import urlcat from 'urlcat';

import { createClient } from '../client';

import { API_BASE_URL } from '../config';
import { COOKIE } from '../config/endpoints';

const client = createClient(urlcat(API_BASE_URL, COOKIE));

export default class Cookie {
  static async get(target) {
    return client.get('', {
      params: { target },
    });
  }
}
