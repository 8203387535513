import urlcat from 'urlcat';

import { createClient } from '../client';

import { API_BASE_URL } from '../config';
import { TRANSLATION } from '../config/endpoints';

const client = createClient(urlcat(API_BASE_URL, TRANSLATION));

export default class Translation {
  static async list() {
    return client.get('');
  }

  static async get(id) {
    return client.get(`/${id}`);
  }

  static async recordWebVTT(id) {
    return client.get(`/${id}/record-webvtt`);
  }
}
