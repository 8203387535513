import videojs from 'video.js';

import MarkerPoint from './MarkerPoint';

const Component = videojs.getComponent('Component');

export default class MarkersBar extends Component {
  constructor(player, { markers = [] }) {
    super(player);

    this.markers = markers.map(marker => new MarkerPoint(player, marker));
  }

  get count() {
    return this.markers.length;
  }

  update() {
    const duration = this.player_.duration && this.player_.duration();

    if (!duration) {
      return;
    }

    this.markers
      .filter(({ startTime }) => startTime < duration)
      .forEach(marker => {
        this.addChild(marker);

        marker.update(duration);
      });
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-marker-bar',
    });
  }
}
