import videojs from 'video.js';

import MarkerPointTip from './MarkerPointTip';

const Component = videojs.getComponent('Component');

export default class MarkerPoint extends Component {
  constructor(player, { startTime, text }) {
    super(player);

    this.startTime = startTime;
    this.text = text;

    this.tip = new MarkerPointTip(player, { text });

    this.mouseDisplay = player.getDescendant([
      'ControlBar',
      'ProgressControl',
      'SeekBar',
      'MouseTimeDisplay',
    ]);

    this.addChild(this.tip);

    this.enableTouchActivity();
    this.enableEvents();
  }

  enableEvents() {
    this.on('mouseenter', () => {
      this.mouseDisplay.hide();

      this.tip.show();
    });

    this.on('mouseleave', () => {
      this.mouseDisplay.show();

      this.tip.hide();
    });

    this.on('click', event => {
      event.preventDefault();
      event.stopPropagation();

      this.player_.currentTime(this.startTime);
    });
  }

  createEl() {
    return videojs.dom.createEl('div', {
      className: 'vjs-marker-point',
    });
  }

  update(duration) {
    this.el_.style.left = (this.startTime / duration) * 100 + '%';
  }
}
