import isPlainObject from 'lodash/isPlainObject';
import get from 'lodash/get';

const copyValues = function(target, source, ...ignore) {
  if (!isPlainObject(target) || !isPlainObject(source)) {
    throw new Error('Target and source must be plain js objects');
  }

  return Object.entries(target).reduce((acc, [key, oldValue]) => {
    if (ignore.includes(key)) {
      return acc;
    }

    acc[key] = get(source, key, oldValue);

    return acc;
  }, {});
};

export { copyValues };
