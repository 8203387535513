import videojs from 'video.js';

import MarkersBar from './src/MarkersBar';

const Plugin = videojs.getPlugin('plugin');

class ChapterMarkersPlugin extends Plugin {
  constructor(player) {
    super(player);

    this.player.addClass('vjs-chapter-markers');

    this.player.on('loadedmetadata', () => {
      this.updateMarkers();
    });
  }

  createBar() {
    const markers = this.getMarkersFromTracks();

    return new MarkersBar(this.player, { markers });
  }

  updateMarkers() {
    if (this.markersBar) {
      this.markersBar.dispose();
    }

    const container = this.player.getDescendant(['ControlBar', 'ProgressControl', 'SeekBar']);

    this.markersBar = this.createBar();

    if (!this.markersBar.count) {
      return;
    }

    container.addChild(this.markersBar);

    this.markersBar.update();
  }

  getMarkersFromTracks() {
    const track = this.findChaptersTrack();

    if (!track) {
      return;
    }

    return Array.from(track.cues).reduce((acc, { startTime, text }) => {
      if (!startTime || !text) {
        return acc;
      }

      acc.push({ startTime, text });

      return acc;
    }, []);
  }

  findChaptersTrack() {
    const tracks = this.player.remoteTextTracks();

    return Array.from(tracks).find(({ kind }) => kind === 'chapters');
  }
}

ChapterMarkersPlugin.defaultState = {};

videojs.registerPlugin('chapterMarkers', ChapterMarkersPlugin);
