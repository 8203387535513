import Statistic from '@/api/http/statistic';

import { STATISTIC as enabled } from '@/config/features';

export default class StatisticService {
  static enabled = enabled;

  static enable() {
    this.enabled = true;
  }

  static disable() {
    this.enabled = false;
  }

  static event(type, { category, locale, payload }) {
    if (!this.enabled) {
      return;
    }

    const params = {
      type: this.buildType(type, category),
      locale,
      payload,
    };

    Statistic.create(params).catch(() => {});
  }

  static buildType(type, category) {
    if (!category) {
      return type;
    }

    return `${category}__${type}`;
  }
}
