<template>
  <div v-bind:translate.prop="false">
    <div data-vjs-player>
      <video
        ref="video"
        class="video-js vjs-default-skin"
        :class="classes"
        :autoplay="autoplay"
        :preload="preload"
        playsinline
      ></video>

      <slot
        name="default"
        v-bind:live="live"
        v-bind:fullscreen="fullscreen"
        v-bind:state="state"
      ></slot>

      <slot
        name="fullscreen"
        v-if="fullscreen"
        v-bind:live="live"
        v-bind:fullscreen="fullscreen"
        v-bind:state="state"
      ></slot>
    </div>
  </div>
</template>

<script>
import videojs from 'video.js';

import ru from 'video.js/dist/lang/ru.json';
import en from 'video.js/dist/lang/en.json';

import 'videojs-contrib-quality-levels';
import 'videojs-hls-quality-selector';
import '@/lib/videojs-chapter-markers';

export default {
  props: {
    source: {
      type: Object,
    },
    initialSource: {
      type: Object,
    },
    preload: {
      type: String,
      default: 'metadata',
    },
    initialVolume: {
      type: [Number],
      default: 0.7,
    },
    poster: {
      type: String,
    },
    autoplay: {
      type: Boolean,
      default: false,
    },
    aspectRatio: {
      type: String,
      default: '16:9',
    },
    playbackRates: {
      type: Array,
      default: () => [],
    },
    tracks: {
      type: Array,
      default: () => [],
    },
    language: {
      type: String,
      default: 'en',
    },
    time: {
      type: Number,
      default: 0,
    },
    liveui: {
      type: Boolean,
    },
    bigPlayCentered: {
      type: Boolean,
    },
    showBigPlayOnPause: {
      type: Boolean,
    },
    displayCurrentQuality: {
      type: Boolean,
    },
  },
  data() {
    return {
      live: null,
      fullscreen: null,
      state: {
        paused: null,
        ended: null,
      },
    };
  },
  computed: {
    classes() {
      return {
        'vjs-big-play-centered': this.bigPlayCentered,
        'vjs-show-big-play-button-on-pause': this.showBigPlayOnPause,
      };
    },
  },
  watch: {
    source() {
      this.changeSource();
    },
    language(language) {
      this.player.language(language);
    },
    time(time) {
      if (!Number.isFinite(time)) {
        return;
      }

      this.currentTime(time);
    },
    tracks() {
      this.updateTracks();
    },
  },
  mounted() {
    this.player = videojs(
      this.$refs.video,
      {
        liveui: this.liveui,
        preload: this.preload,
        autoplay: this.autoplay,
        poster: this.poster,
        aspectRatio: this.aspectRatio,
        fill: true,
        fluid: true,
        controls: true,
        bigPlayButton: true,
        textTrackSettings: false,
        playbackRates: this.playbackRates,
        language: this.language,
        liveTracker: {
          liveTolerance: 20,
        },
        languages: {
          en,
          ru: {
            ...ru,
            Quality: 'Качество',
          },
        },
        html5: {
          nativeTextTracks: false,
          hls: {
            overrideNative: !videojs.browser.IS_ANY_SAFARI,
            withCredentials: true,
          },
        },
      },
      () => this.$emit('ready')
    );

    this.player.ready(() => {
      this.player.src(this.initialSource || this.source);

      this.enableQualityLevels();
      this.enableChapterMarkers();

      this.updateTracks();
    });

    this.volume(this.initialVolume);

    this.setupEventListeners();
    this.setupEventEmitters();
  },
  beforeDestroy() {
    if (!this.player) {
      return;
    }

    this.player.dispose();
  },
  methods: {
    play() {
      this.player.play();
    },
    pause() {
      this.player.pause();
    },
    paused() {
      return this.player.paused();
    },
    ended() {
      return this.player.ended();
    },
    volume(level = 1) {
      this.player.volume(level);
    },
    resetToStart() {
      this.player.hasStarted(false);
    },
    currentTime(time) {
      return this.player.currentTime(time);
    },
    atLiveEdge() {
      return this.player.liveTracker.atLiveEdge();
    },
    isFullscreen() {
      return this.player.isFullscreen();
    },
    addRemoteTextTrack(track, promise = false) {
      const textTrack = this.player.addRemoteTextTrack(track);

      if (!promise) {
        return textTrack;
      }

      return new Promise((resolve, reject) => {
        textTrack.onload = () => resolve(textTrack);
        textTrack.onerror = reject;
      });
    },
    remoteTextTracks() {
      return this.player.remoteTextTracks();
    },
    removeRemoteTextTrack(track) {
      this.player.removeRemoteTextTrack(track);
    },
    changeSource() {
      const played = !this.paused();

      this.resetToStart();

      this.updateSource();

      if (played) {
        this.play();
      }
    },
    updateSource() {
      this.player.src(this.source);
    },
    enableQualityLevels() {
      this.player.qualityLevels();
      this.player.hlsQualitySelector({
        displayCurrentQuality: this.displayCurrentQuality,
      });
    },
    enableChapterMarkers() {
      this.player.chapterMarkers();
    },
    setupEventListeners() {
      this.player.on('firstplay', this.handleFirstPlay);
      this.player.liveTracker.on('liveedgechange', this.handleLiveEdgeChange);
    },
    setupEventEmitters() {
      this.player.on(
        ['firstplay', 'play', 'pause', 'waiting', 'stalled', 'error', 'abort', 'suspend'],
        event => {
          this.$emit('status', event);
          this.$emit('state', this.updateState());

          this.$emit(event.type, this.currentTime());
        }
      );

      // Возврат скорости на 1 при просмотре с ускорением на Safari
      this.player.on('ended', () => {
        const currentTime = this.player.currentTime();
        const seekableEnd = this.player.liveTracker.seekableEnd();
        const isLive = this.player.liveTracker.isLive();
        const isBehindLiveEdge = this.player.liveTracker.behindLiveEdge();

        if (Math.abs(currentTime - seekableEnd) <= 1 && isLive && isBehindLiveEdge) {
          this.player.playbackRate(1);
          this.play();
          this.player.liveTracker.seekToLiveEdge();
        }
      });

      // Возврат скорости на 1 при просмотре с ускорением на Chrome и Firefox
      this.player.on('waiting', () => {
        const currentTime = this.player.currentTime();
        const liveCurrentTime = this.player.liveTracker.liveCurrentTime();

        if (currentTime > liveCurrentTime) {
          this.player.liveTracker.seekToLiveEdge();
        }
      });

      this.player.on('timeupdate', event => {
        this.$emit(event.type, this.currentTime());
      });

      this.player.on('loadstart', event => {
        this.$emit(event.type, this.player.src());
      });

      this.player.on('fullscreenchange', event => {
        this.$emit(event.type, this.updateFullscreen());
      });

      this.player.on(['enterpictureinpicture', 'leavepictureinpicture', 'ended'], event => {
        this.$emit('service', event);

        this.$emit(event.type, event);
      });

      this.player.on('dispose', () => this.$emit('dispose'));

      this.player.liveTracker.on('liveedgechange', event => {
        this.$emit(event.type, this.updateLive());
      });
    },
    updateState() {
      return Object.assign(this.state, {
        paused: this.paused(),
        ended: this.ended(),
      });
    },
    updateLive() {
      this.live = this.atLiveEdge();

      return this.live;
    },
    updateFullscreen() {
      this.fullscreen = this.isFullscreen();

      return this.fullscreen;
    },
    updateTracks() {
      this.clearTracks();

      const tracks = this.tracks.map(track => {
        return this.addRemoteTextTrack(track, true);
      });

      Promise.allSettled(tracks).then(() => {
        this.player.chapterMarkers().updateMarkers();
      });
    },
    clearTracks() {
      const tracks = this.remoteTextTracks();

      Array.from(tracks).forEach(track => {
        this.removeRemoteTextTrack(track);
      });
    },
    handleFirstPlay() {
      if (!this.time) {
        return;
      }

      this.currentTime(this.time);
    },
    handleLiveEdgeChange() {
      this.player.playbackRate(1);

      const { playbackRateMenuButton: rateMenu } = this.player.controlBar;

      if (!rateMenu) {
        return;
      }

      if (this.atLiveEdge()) {
        rateMenu.disable();
      } else {
        rateMenu.enable();
      }
    },
  },
};
</script>
