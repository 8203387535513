import Cookies from 'js-cookie';
import Cookie from '@/api/http/cookie';

export default {
  methods: {
    async refreshCredentials(source) {
      return Cookie.get(source)
        .then(({ data }) => this.populateCredentials(data))
        .catch(() => {
          this.$toast.error(this.$t('components.player.error.credentials'));
        });
    },
    populateCredentials(credentials = {}) {
      const { hostname } = window.location;

      const expires = (1 / 24) * 3;
      const domain = hostname.includes('.') ? `.${hostname}` : hostname;

      Object.entries(credentials).forEach(([key, value]) => {
        Cookies.set(key, value, { expires, domain, sameSite: 'Strict', secure: true });
      });
    },
  },
};
