import videojs from 'video.js';

const TimeTooltip = videojs.getComponent('TimeTooltip');

export default class MarkerPointTip extends TimeTooltip {
  constructor(player, { text }) {
    super(player);

    this.text = text;

    this.addClass('vjs-marker-point-tip');

    this.contentEl().textContent = this.text;

    this.hide();
  }
}
