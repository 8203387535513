import StatisticService from '@/services/statistic';

const PROGRESS_SECONDS = 60;

export default {
  data() {
    return {
      minutes: 0,
    };
  },
  methods: {
    trackPlayerEvent(event, params = {}) {
      this.$gtag.event(event, params);
    },
    trackPlayerStateEvent(type, params = {}) {
      this.trackPlayerEvent(`player_state`, {
        type,
        ...params,
      });
    },
    trackStatisticEvent(type, payload = {}) {
      StatisticService.event(type, {
        category: 'player',
        locale: this.locale,
        payload,
      });
    },
    validateProgress(progress) {
      const minutes = Math.floor(progress / PROGRESS_SECONDS);

      if (this.minutes >= minutes) {
        return false;
      }

      this.minutes = minutes;

      return true;
    },
  },
};
