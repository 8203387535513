import urlcat from 'urlcat';

import { createClient } from '../client';

import { API_BASE_URL } from '../config';
import { STATISTIC } from '../config/endpoints';

const client = createClient(urlcat(API_BASE_URL, STATISTIC));

export default class Statistic {
  static async create({ locale, type, payload = {} }) {
    return client.post('', { locale, type, payload });
  }
}
