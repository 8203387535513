<template>
  <div>
    <v-row justify-sm="space-between" no-gutters>
      <v-col cols="12" sm="8">
        <h1 class="text-h6 font-weight-regular">{{ title }}</h1>
        <div class="mt-1 text-subtitle-1 secondary--text text--lighten-4">
          <time :datetime="date.toISOString()" :title="date.toLocaleString()">
            {{ $d(date, 'short') }}
          </time>
        </div>
      </v-col>
      <v-col v-if="slotAppend" cols="auto" class="mt-3 mt-sm-0">
        <slot name="append"></slot>
      </v-col>
    </v-row>

    <v-divider v-if="divider" class="mt-2" />

    <v-row v-if="description || slotChapters" class="mt-4" no-gutters>
      <v-col cols="8" class="text-body-2">
        <p class="text-pre-line" v-if="description" v-text="description"></p>

        <slot name="chapters"></slot>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    date: {
      type: Date,
      required: true,
    },
    divider: {
      type: Boolean,
      default: true,
    },
    description: {
      type: String,
    },
  },
  computed: {
    slotAppend() {
      return !!this.$slots.append;
    },
    slotChapters() {
      return !!this.$slots.chapters;
    },
  },
};
</script>
